import React from "react";
import theme from "theme";
import { Theme, Span, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Cinamonos Balıkçılık Kulübü
			</title>
			<meta name={"description"} content={"Hem meraklılar hem de amatörler için nihai balıkçılık kaçamağı. Sakin bir konumda yer alan inziva yerimiz, uzman rehberler"} />
			<meta property={"og:title"} content={"Ana Sayfa | Cinamonos Balıkçılık Kulübü"} />
			<meta property={"og:description"} content={"Hem meraklılar hem de amatörler için nihai balıkçılık kaçamağı. Sakin bir konumda yer alan inziva yerimiz, uzman rehberler"} />
			<meta property={"og:image"} content={"https://rescinamonos.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rescinamonos.com/img/fishing-icons.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rescinamonos.com/img/fishing-icons.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rescinamonos.com/img/fishing-icons.png"} />
		</Helmet>
		<Components.Header />
		<Section min-height="100vh" padding="0 0 0 0">
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				max-width="none"
				width="100%"
				sm-padding="72px 0px 0px 0px"
			/>
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 50px 0px"
				margin="40px 5% 40px 5%"
				lg-padding="24px 60px 24px 60px"
				sm-padding="0px 20px 0px 20px"
				padding="24px 0px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Span color="--green">
						Cinamonos
					</Span>
					{" "}Balıkçılık Kulübü
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Hem meraklılar hem de amatörler için nihai balıkçılık kaçamağı. Sakin bir konumda yer alan inziva yerimiz, uzman rehberler, konforlu konaklama birimleri ve balık tutma gezinizi unutulmaz kılmak için özel olarak tasarlanmış bir dizi ek hizmetle tamamlanan benzersiz bir balık tutma deneyimi sunar.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="#contact"
						padding="12px 24px 12px 24px"
						color="--light"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						background="--color-green"
						hover-background="--color-primary"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Bizi Ziyaret Edin
					</Link>
					<Link
						href="/testimonials"
						padding="10px 24px 10px 24px"
						color="--darkL1"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-darkL1"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						hover-background="--color-green"
						lg-margin="0px 0px 0px 0px"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						hover-color="--primary"
						hover-border-color="--color-primary"
					>
						Yorumları kontrol edin
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				background="#FFFFFF url(https://rescinamonos.com/img/1.jpg) center center/cover no-repeat scroll padding-box"
				lg-min-height="400px"
			/>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="rgba(0, 0, 0, 0) linear-gradient(120deg,white 0%,--color-lightD2 100%) 0% 0% /auto repeat scroll padding-box"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="65%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					md-text-align="center"
					margin="0px 0px 16px 0px"
					font="--base"
					color="--primary"
					letter-spacing="1px"
				>
					Neden Cinamonos Balıkçılık Kulübünü Seçmelisiniz?
				</Text>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Bizim avantajlarımız
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-gap="36px"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Uzman Balıkçılık Rehberleri
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Bilgili rehberlerimiz sadece en iyi balıkçılık noktalarını bulmakta değil, aynı zamanda tüm beceri seviyelerindeki balıkçılara teknikleri öğretmekte de ustadır.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Konforlu Ev Kiralamaları
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Hafta sonu kaçamakları veya uzun süreli konaklamalar için mükemmel olan rahat, iyi donanımlı evlerimizde kalın.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Yakala ve Pişir Servisi
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Şeflerimizin günlük avınızı beğeninize göre hazırladığı yakala ve pişir servisimizle emeğinizin meyvelerinin tadını çıkarın.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Her Şey Dahil Deneyim
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Olta takımı kiralamadan yem ve olta takımına kadar, başarılı bir balıkçılık günü için ihtiyacınız olan her şeyi sağlıyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-12">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					position="relative"
					padding="0px 0px 70% 0px"
				>
					<Image
						width="100%"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://rescinamonos.com/img/2.jpg"
						object-fit="cover"
						right={0}
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
				width="100%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Ek Avantajlar
				</Text>
			</Box>
			<Box
				flex-direction="column"
				padding="16px 16px 16px 16px"
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				width="33.3333%"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Sakin Ortam - Konumumuz, huzurlu çevresi ve bol balığı nedeniyle seçilmiştir, bu da burayı koşuşturmadan kaçmak isteyenler için ideal bir yer haline getirmektedir.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				width="33.3333%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Topluluk Atmosferi - Balıkçılık meraklılarından oluşan bir toplulukla hikayeler, ipuçları ve güzel zamanlar paylaşın.
				</Text>
			</Box>
			<Box
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				display="flex"
				width="33.3333%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 60% 0px"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
				>
					<Image
						bottom="0px"
						min-height="100%"
						src="https://rescinamonos.com/img/3.jpg"
						display="block"
						width="100%"
						left={0}
						right={0}
						object-fit="cover"
						position="absolute"
						top="auto"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});